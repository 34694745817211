import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import { Link } from 'gatsby';
import React, { Component } from 'react';

type Props = {
  classes: Record<string, string>;
};

class AssistantServiceHero extends Component<Props> {
  render() {
    const { classes } = this.props;

    const svgBackgruond = (
      <div className={classes.svgBackground}>
        <svg
          className={classes.svg}
          role="presentation"
          viewBox="0 0 1351 759.567"
          preserveAspectRatio="xMidYMid slice"
        >
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="__id702__id703"
              x1="0"
              y1="379.783"
              x2="1351"
              y2="379.783"
            >
              <stop stopColor="#faf6cb" stopOpacity="1" offset="0"></stop>
              <stop stopColor="#edd0eb" stopOpacity="1" offset="1"></stop>
            </linearGradient>
          </defs>
          <rect
            x="0"
            y="0"
            width="1351"
            height="759.567"
            fill="url(#__id702__id703)"
          ></rect>
        </svg>
      </div>
    );

    return (
      <section id="assistant-service-hero" className={classes.root}>
        {svgBackgruond}
        <div className={classes.componentWrapper}>
          <div className={classes.imageContainer}>
            <img
              src="/assets/images/support_back2.png"
              alt="背景画像2"
              className={classes.overlayImage}
            />
            <img
              src="/assets/images/support_back1.png"
              alt="メイン画像1"
              className={classes.mainImage}
            />
          </div>
          <div className={classes.content}>
            <div className={classes.logoWrapper}>
              <img
                className={classes.logo}
                src="/assets/images/logo.png"
                alt="なでケア"
              />
            </div>
            <div className={classNames('sawarabi-gothic', classes.message)}>
              <div className={classes.titleWrapper}>
                <div className={classNames(classes.title1, 'barrio')}>LINE</div>
                <div className={classNames(classes.title2, 'corporate-logo')}>
                  女子サッカーの相談窓口
                </div>
              </div>
              <div>
                私たちなでケアは、「みんなが不安を抱えることなく、心配なく大好きなサッカーを思い切り楽しめる環境づくりに貢献したい」という想いのもと、女子サッカーに関するお悩み相談室を設置しました！
              </div>
              <div>
                現役・引退を含む女子サッカー選手が一人ひとりのお悩みに寄り添っていきます！
                <br />
                悩みを一人で抱え込まず、まずはなでケアの選手に相談してみませんか？
              </div>
              <div className={classes.serviceButtonWrapper}>
                <Link
                  to="https://page.line.me/446tkmtk"
                  className={classes.serviceButton}
                >
                  <div className={classNames('corporate-logo')}>
                    いますぐ話してみる！
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    svgBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -4,
    },
    svg: {
      width: '100%',
      height: '100%',
    },
    componentWrapper: {
      display: 'flex',
      width: '100%',
      maxWidth: 1280,
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    imageContainer: {
      position: 'relative',
      zIndex: 2,
      width: 500,
      marginTop: 50,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    mainImage: {
      width: 500,
      height: 'auto',
      zIndex: 1,
      transform: 'scale(0.8)',
      margin: 0,
    },
    overlayImage: {
      position: 'absolute',
      width: 1000,
      height: 'auto',
      zIndex: -1,
      margin: 0,
    },
    logoWrapper: {
      width: '100%',
      textAlign: 'center',
    },
    logo: {
      width: 100,
    },
    content: {
      maxWidth: 600,
      textAlign: 'left',
      width: '100%',
      paddingTop: 60,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    serviceButton: {
      fontSize: 30,
      padding: '20px 20px',
      background: 'rgb(252, 81, 96)',
      color: 'white',
      borderRadius: 50,
      textAlign: 'center',
      width: 350,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    serviceButtonWrapper: {
      marginTop: 40,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
    },
    titleWrapper: {
      textAlign: 'center',
      marginBottom: 30,
    },
    title1: {
      fontSize: 42,
      marginBottom: 10,
    },
    title2: {
      fontSize: 35,
    },
    message: {
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      },
    },
  });

export default withStyles(styles)(AssistantServiceHero);
