import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import React, { Component } from 'react';

type Props = {
  classes: {
    kiyaku: string;
    kiyakuTitle: string;
    kiyakuBorder: string;
    firstPara: string;
    secondPara: string;
    thirdPara: string;
    thirdParaList: string;
  };
};

class HarassmentTermsContent extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div id="assistant-service-terms-content" className={classes.kiyaku}>
        <hr className={classes.kiyakuBorder} />
        <ul className={classes.firstPara}>
          <li>第１条（本規約への同意）</li>
          <li>
            <ol className={classes.secondPara}>
              <li>
                この利用規約は、一般社団法人なでしこケア（以下「本法人」といいます）が設置する、ジェンダー問題、ハラスメント等を含めた女子サッカーの活動現場で生じている問題（以下「相談内容」といいます）に関する通報又は相談を受け付けるための窓口（以下「相談窓口」といいます）の利用方法を定めた規約です（以下「本規約」といいます）。
              </li>
              <li>
                利用者は、相談窓口を利用する場合、本規約に同意頂いたものとみなされます。
              </li>
            </ol>
          </li>
        </ul>
        <ul className={classes.firstPara}>
          <li>第２条（利用対象者） </li>
          <li>
            <ol className={classes.secondPara}>
              <li>
                相談窓口の利用対象者は、次の各号のいずれかに該当する個人とします。
              </li>
              <ol className={classes.thirdPara}>
                <li className={classes.thirdParaList}>
                  サッカーを競技活動として日常的に行っている育成年代（6歳～18歳）の女子選手
                </li>
                <li className={classes.thirdParaList}>
                  サッカーを競技活動として日常的に行っている女子大学生
                </li>
                <li className={classes.thirdParaList}>
                  上記１又は２の女子選手の指導者及び当該選手が所属するクラブ又は部活動のスタッフ
                </li>
                <li className={classes.thirdParaList}>
                  上記１又は２の女子選手のご家族
                </li>
                <li className={classes.thirdParaList}>上記の方に準ずる方</li>
              </ol>
            </ol>
          </li>
        </ul>
        <ul className={classes.firstPara}>
          <li>第３条（通報又は相談の対象行為）</li>
          <li>
            <ol className={classes.secondPara}>
              <li>
                相談窓口の通報又は相談の対象となる行為は、第１条に定めた相談内容とします。ただし、サッカーの技術的な問題、及び医療、税務等の専門的知識を必要とし、第６条による法律専門家への協力要請によっても対応が困難な問題を除きます。
              </li>
            </ol>
          </li>
        </ul>
        <ul className={classes.firstPara}>
          <li>第４条（通報又は相談の受付）</li>
          <li>
            <ol className={classes.secondPara}>
              <li>
                相談窓口は、実名及び匿名のいずれの通報又は相談も受け付けます。
              </li>
              <li>
                相談窓口への通報又は相談は、本法人が設置する入力フォームを通じて行うものとします。
              </li>
              <li>
                相談窓口に相談内容に関する通報又は相談があった場合、本法人の指定する相談対応者が、当該通報又は相談に対応します。相談対応者は誠実に対応を行いますが、利用者の状況が確実に改善することや、利用者のご希望に必ず沿うことの保証はいたしかねます。
              </li>
              <li>
                利用者は、相談窓口の相談対応者が自身の競技活動やその他の事情により、通報又は相談への対応を速やかに行えない場合があることを了承するものとします。
              </li>
              <li>
                本法人は、利用者の通報又は相談の内容が相談内容に該当しないと判断した場合、個人に関する中傷や虚偽の事実に基づく主張など通報又は相談の内容が相談窓口の設置目的に照らして不適切であると判断した場合、又は、通報もしくは相談を行った者が第２条の利用対象者に該当しないと判断した場合、相談窓口の利用をお断りすること、及び利用者の通報又は相談に対し回答を行わないことがあります。
              </li>
              <li>
                本法人は、利用者の連絡先が確認できないこと等によって、通報又は相談への対応、法律専門家への協力要請を遂げられないとしても、その責任を問われることはないものとします。
              </li>
            </ol>
          </li>
        </ul>
        <ul className={classes.firstPara}>
          <li>第５条（利用者の個人情報等の保護）</li>
          <li>
            <ol className={classes.secondPara}>
              <li>
                本法人は、利用者の個人情報及び通報又は相談の内容に関して秘密を保持し、当該利用者の同意を得た場合又は法令で認められている場合を除き、当該利用者の個人情報及び通報又は相談の内容を第三者に開示しないものとします。また、本法人は、相談窓口の相談対応者に対し、同様の義務を負わせるものとします。
              </li>
              <li>
                本法人は、相談窓口の相談対応者に対し、正当な理由がない限り、利用者の個人情報を開示するよう求めないものとします。
              </li>
              <li>
                本法人及び相談窓口の相談対応者は、利用者の個人情報を、利用者からの通報又は相談の対応及び次条に定める法律専門家への協力要請のために利用します。
              </li>
              <li>
                その他、個人情報の取扱いに関する事項は、本法人のプライバシーポリシー（
                <Link to="/privacy/">https://nadecare.jp/privacy/</Link>
                ）をご覧ください。
              </li>
              <li>
                相談窓口におけるシステムには必要な安全対策を講じていますが、システムやプログラム、ネットワーク等の完全な動作及び安全を保証するものではありません。
              </li>
              <li>
                本法人は、利用者が本法人に提供した情報、データ等を、個人を特定できない形での統計的な情報又は相談事例として、利用及び公開することがあります。利用者は当該態様による情報、データ等の利用について異議を唱えないものとします。
              </li>
            </ol>
          </li>
        </ul>
        <ul className={classes.firstPara}>
          <li>第６条（法律専門家への協力要請）</li>
          <li>
            <ol className={classes.secondPara}>
              <li>
                相談対応者は、利用者の通報又は相談の内容に応じて、法律専門家の協力が必要であると考える場合、利用者の同意を得て、本法人が連携する法律専門家に対し、通報又は相談の内容の対処への協力を要請することができるものとします。
              </li>
              <li>
                本法人は、前項の協力要請を受けた法律専門家に対し、要請を受けた案件に対応するにあたり、利用者の個人情報及び通報又は相談の内容に関して秘密として保持させるものとします。
              </li>
              <li>
                本法人及び相談対応者は、法律専門家による法的助言について一切の責任を負わないものとします。
              </li>
            </ol>
          </li>
        </ul>
        <ul className={classes.firstPara}>
          <li>第７条（その他）</li>
          <li>
            <ol className={classes.secondPara}>
              <li>
                相談窓口をご利用の際は、スマートフォン・携帯電話・パソコンなどの設備やインターネット回線などの通信費、ブラウザ等の一切を、自己責任と自己負担においてご準備ください。
              </li>
              <li>
                相談窓口及びそのシステム、及び本規約などは、予告なく変更・停止することがあります。本規約を変更する場合、変更後の本規約の施行時期及び内容を本法人ウェブサイト上での掲示その他の適切な方法により周知いたします。
              </li>
            </ol>
          </li>
        </ul>
        <div style={{ textAlign: 'right' }}>一般社団法人なでしこケア</div>
        <div style={{ textAlign: 'left' }}>2020年12月1日 制定</div>

        <hr className={classes.kiyakuBorder} />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    kiyaku: {
      overflow: 'scroll-y',
      lineHeight: 1.3,
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      fontSize: 17,
      backgroundColor: 'white',
      borderRadius: 30,
      overflowY: 'scroll',
      padding: '40px 50px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 20px',
        height: 600,
      },
      [theme.breakpoints.down('xs')]: {
        padding: '10px 5px',
        fontSize: 13,
        height: 500,
      },
    },
    kiyakuBorder: {
      marginTop: 20,
      marginBottom: 20,
      height: '10px',
      backgroundImage:
        'linear-gradient(90deg, hsla(0, 0%, 100%, 1),hsla(0, 0%, 100%, 1) 33.33%,  hsla(0, 0%, 50%, 0) 33.33%,hsla(0, 0%, 50%, 0) 100%)',
      backgroundSize: '3px 100%',
      margin: 'auto',
    },
    firstPara: {
      listStyleType: 'none',
      marginBottom: 30,
      textAlign: 'left',
    },
    secondPara: {
      listStyleType: 'decimal',
      // textIndent: "1em",
      paddingLeft: 15,
    },
    thirdPara: {
      listStyleType: 'decimal',
      // textIndent: "2em",
    },
    thirdParaList: {
      listStyleType: 'none',
      listStylePosition: 'inside',
      counterIncrement: 'cnt',
      '&:before': {
        display: 'marker',
        // counterIncrement: "num",
        content: '"(" counter(cnt)") "',
      },
    },
  });

export default withStyles(styles)(HarassmentTermsContent);
