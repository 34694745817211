import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import { Link } from 'gatsby';
import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

import TermsContent from './TermsContent';

import { scrollToTarget } from '@/utils';

type Props = {
  classes: {
    root: string;
    componentWrapper: string;
    content: string;
    svgBackground: string;
    svg: string;
    explanation: string;
    image: string;
    overlayImage: string;
    message: string;
    logoWrapper: string;
    logo: string;
    serviceButton: string;
    serviceButtonWrapper: string;
    bottom: string;
    lineLogo: string;
    qrCode: string;
    kiyakuTitle: string;
    terms: string;
    termsLink: string;
  };
};

type State = {
  checked: boolean;
};

class HarassmentTerms extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <section id="assistant-service-terms">
        <AssistantServiceHero classes={classes} />
        <div
          className={classNames(classes.kiyakuTitle, 'corporate-logo')}
          id="terms"
        >
          利用規約
        </div>
        <div className={classes.componentWrapper}>
          <TermsContent />
        </div>
      </section>
    );
  }
}

class AssistantServiceHero extends Component<Props> {
  _handleOnClick = (menuItem: string) => () => {
    scrollToTarget(menuItem);
  };

  render() {
    const { classes } = this.props;

    return (
      <section id="assistant-service-hero" className={classes.root}>
        <div className={classes.svgBackground}>
          <svg
            className={classes.svg}
            role="presentation"
            viewBox="0 0 1351 759.567"
            preserveAspectRatio="xMidYMid slice"
          >
            <defs>
              <linearGradient
                gradientUnits="userSpaceOnUse"
                id="__id702__id703"
                x1="0"
                y1="379.783"
                x2="1351"
                y2="379.783"
              >
                <stop stopColor="#faf6cb" stopOpacity="1" offset="0"></stop>
                <stop stopColor="#edd0eb" stopOpacity="1" offset="1"></stop>
              </linearGradient>
            </defs>
            <rect
              x="0"
              y="0"
              width="1351"
              height="759.567"
              fill="url(#__id702__id703)"
            ></rect>
          </svg>
        </div>
        <LazyLoad offset={200} once className={classes.image}>
          <img
            src="/assets/images/support_back2.png"
            alt="背景画像2"
            className={classes.overlayImage}
          />
        </LazyLoad>
        <div className={classes.content}>
          <div>
            <div>
              <div className={classes.logoWrapper}>
                <img
                  className={classes.logo}
                  src="/assets/images/logo.png"
                  alt="なでケア"
                />
              </div>
              <div className={classNames(classes.terms, 'sawarabi-gothic')}>
                相談を開始するには、利用規約への同意が必要です。
                <br />『
                <div
                  className={classes.termsLink}
                  onClick={this._handleOnClick('terms')}
                >
                  こちら
                </div>
                』から必ずご確認の上、相談を開始してください。
                <br />
                相談していただいた場合、規約に同意したものとみなします。
              </div>
              <div className={classes.serviceButtonWrapper}>
                <Link
                  to="https://page.line.me/446tkmtk"
                  className={classes.serviceButton}
                >
                  <div className={classNames('corporate-logo')}>
                    LINEで友達追加して
                    <br />
                    いますぐ話してみる
                  </div>
                </Link>
              </div>
              <div className={classNames(classes.bottom, 'sawarabi-gothic')}>
                <div>
                  <img
                    className={classes.lineLogo}
                    src="/assets/images/LINE_Brand_icon.png"
                    alt="LINE"
                  />
                </div>
                <div>
                  なでケア
                  <br />
                  女子サッカーのお悩み相談窓口
                </div>
                <div>
                  <img
                    className={classes.qrCode}
                    src="/assets/images/qrcode.jpg"
                    alt="LINE"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    componentWrapper: {
      maxWidth: 800,
      margin: '40px auto',
      border: '1px solid #000',
      borderRadius: 30,
      [theme.breakpoints.down('sm')]: {
        margin: '0 20px',
      },
    },
    svgBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -4,
    },
    svg: {
      width: '100%',
      height: '100%',
    },
    content: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
    },
    image: {
      position: 'absolute',
      zIndex: -3,
    },
    overlayImage: {
      height: 650,
    },
    logoWrapper: {
      marginTop: 60,
    },
    logo: {
      width: 140,
    },
    serviceButton: {
      fontSize: 30,
      padding: '20px 20px',
      background: 'rgb(252, 81, 96)',
      color: 'white',
      borderRadius: 50,
      textAlign: 'center',
      width: 350,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    serviceButtonWrapper: {
      marginTop: 40,
      display: 'flex',
      justifyContent: 'center',
    },
    bottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      marginTop: 60,
    },
    lineLogo: {
      width: 50,
    },
    qrCode: {
      width: 100,
    },
    kiyakuTitle: {
      paddingTop: 30,
      marginTop: 100,
      fontSize: 30,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        paddingBottom: 20,
        fontSize: 20,
      },
    },
    terms: {
      zIndex: 100,
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    termsLink: {
      cursor: 'pointer',
      display: 'inline-block',
      textDecoration: 'underline',
      color: 'blue',
    },
  });

export default withStyles(styles)(HarassmentTerms);
