import React, { Component, JSX } from 'react';

import Adviser from '@/components/assistant-service/Adviser';
import Hero from '@/components/assistant-service/Hero';
import Terms from '@/components/assistant-service/Terms';
import WhoWhat from '@/components/assistant-service/WhoWhat';
import Layout from '@/components/layout';
import SEO from '@/components/seo';

type Props = {
  requestProcess: void;
  completeProcess: void;
  isProcessing: boolean;
  data: Record<string, unknown>;
  wpModel: Record<string, unknown>;
  classes: Record<string, string>;
};

class AssistantServicePage extends Component<Props> {
  render(): JSX.Element {
    return (
      <Layout className="pt20">
        <SEO title="お悩み相談窓口" />
        <Hero />
        <WhoWhat />
        <Adviser />
        <Terms />
      </Layout>
    );
  }
}

export default AssistantServicePage;
