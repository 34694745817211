import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import CautionIcon from '@material-ui/icons/NewReleases';
import classNames from 'clsx';
import React, { Component } from 'react';

type Props = {
  classes: Record<string, string>;
};

class AssistantServiceWhoWhat extends Component<Props> {
  render() {
    const { classes } = this.props;

    const ballIcon = (
      <img
        src="/assets/images/ball.png"
        className={classes.ballIcon}
        alt="ball"
      />
    );

    const svgBackgruond = (
      <svg
        className={classes.hr}
        role="presentation"
        viewBox="0 0 1351 759.567"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="__id702__id703"
            x1="0"
            y1="379.783"
            x2="1351"
            y2="379.783"
          >
            <stop stopColor="#faf6cb" stopOpacity="1" offset="0"></stop>
            <stop stopColor="#edd0eb" stopOpacity="1" offset="1"></stop>
          </linearGradient>
        </defs>
        <rect
          x="0"
          y="0"
          width="1351"
          height="759.567"
          fill="url(#__id702__id703)"
        ></rect>
      </svg>
    );

    return (
      <section id="assistant-service-who-what" className={classes.root}>
        <div className={classNames(classes.componentWrapper)}>
          <div className="mt60">
            <p className={classes.subTitle}>
              相談（そうだん）を始（はじ）めるまえに...
            </p>
            {svgBackgruond}
            <div className={classes.section}>
              {ballIcon}
              やくそく
            </div>
            <table className={classes.table}>
              <tr>
                <th className={classes.tableTh}>①</th>
                <td className={classes.tableTd}>
                  あなたの秘密（ひみつ）はかならず守（まも）ります
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>②</th>
                <td className={classes.tableTd}>
                  お話（はなし）を聞きながら、一緒（いっしょ）に考（かんが）えます
                </td>
              </tr>
            </table>
            <div className={classes.section}>
              <div>{ballIcon}</div>
              <div>だれが</div>
            </div>
            <table className={classes.table}>
              <tr>
                <th className={classes.tableTh}>①</th>
                <td className={classes.tableTd}>
                  サッカーチーム（クラブ/ぶかつ）に所属（しょぞく）する育成年代（いくせいねんだい）の女の子（7さい〜22さい）
                </td>
              </tr>
            </table>
            <div className={classes.section}>
              <div>{ballIcon}</div>
              <div>なにを</div>
            </div>
            <table className={classes.table}>
              <tr>
                <th className={classes.tableTh}>①</th>
                <td className={classes.tableTd}>
                  サッカーの活動中（かつどうちゅう）におこった問題（もんだい）
                  <br />
                  たとえ：セクハラ・暴力・言葉の暴力・いじめ・嫌がらせ など
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>②</th>
                <td className={classes.tableTd}>
                  身体（からだ）や心（こころ）の問題（もんだい）
                  <br />
                  たとえ：生理のこと・チームメイトを好きになった など
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>③</th>
                <td className={classes.tableTd}>
                  その他、サッカーの活動（かつどう）に影響（えいきょう）するようなこと
                </td>
              </tr>
            </table>
            <div className={classes.flexBoxStart}>
              <CautionIcon
                fontSize="large"
                className={classNames(classes.caution, 'mr10')}
              />
              <p className={classes.caution}>
                この相談窓口では、サッカーの技術的な指導や、サッカーの活動中以外で起こったことに関する相談は受け付けておりません
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 40,
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    componentWrapper: {
      margin: '0 auto',
      maxWidth: 1280,
      paddingTop: 0,
      paddingLeft: 40,
      paddingRight: 40,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    subTitle: {
      marginTop: 50,
      fontSize: 23,
      fontWeight: 'bold',
      marginBottom: 14,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
        marginTop: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        marginTop: 20,
      },
    },
    border: {
      size: 2,
      backgroundColor: 'white',
      height: 5,
    },
    section: {
      marginBottom: 10,
      fontSize: 20,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    table: {
      border: 'none',
      fontSize: 20,
      marginLeft: 30,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 15,
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    ballIcon: {
      margin: '0 10px 0 0',
      padding: 0,
      height: 30,
      [theme.breakpoints.down('sm')]: {
        height: 30,
      },
      [theme.breakpoints.down('xs')]: {
        height: 20,
      },
    },
    tableTh: {
      border: 'none',
      paddingLeft: 0,
      padding: 5,
    },
    tableTd: {
      border: 'none',
      height: '10px',
      padding: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 17,
      },
    },
    caution: {
      color: 'red',
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: 17,
      },
    },
    flexBoxStart: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    hr: {
      height: 12,
      width: '100%',
      marginBottom: 20,
    },
  });

export default withStyles(styles)(AssistantServiceWhoWhat);
