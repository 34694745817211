import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import React, { Component } from 'react';

//const noImage = '/assets/images/noImage.jpg';

type Props = {
  classes: {
    root: string;
    componentWrapper: string;
    flexBoxBetweenRow: string;
    largeSubTitle: string;
    border: string;
    hr: string;
    flexBoxCenter: string;
    squareImg: string;
    roundImg: string;
    playerInfo: string;
    playerName: string;
    flexBoxAround: string;
    flexBoxAround2: string;
    width100: string;
    support: string;
    smallCaution: string;
    section: string;
    company: string;
    advider: string;
  };
};

class AssistantServiceAdviser extends Component<Props> {
  render() {
    const { classes } = this.props;

    const svgBackgruond = (
      <svg
        className={classes.hr}
        role="presentation"
        viewBox="0 0 1351 759.567"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="__id702__id703"
            x1="0"
            y1="379.783"
            x2="1351"
            y2="379.783"
          >
            <stop stopColor="#faf6cb" stopOpacity="1" offset="0"></stop>
            <stop stopColor="#edd0eb" stopOpacity="1" offset="1"></stop>
          </linearGradient>
        </defs>
        <rect
          x="0"
          y="0"
          width="1351"
          height="759.567"
          fill="url(#__id702__id703)"
        ></rect>
      </svg>
    );

    return (
      <section className={classes.root}>
        <div className={classes.componentWrapper}>
          <div id="assitant-service-members">
            <div className={classes.flexBoxBetweenRow}>
              <p className={classes.largeSubTitle}>
                私たちが、みんなの相談を聞きます！
                <p className={classes.smallCaution}>
                  ※相談（そうだん）選手（せんしゅ）はえらべません
                </p>
              </p>
            </div>
            {svgBackgruond}
            <div className={classes.flexBoxCenter}>
              <div className={classes.playerInfo}>
                <p
                  className={classNames(classes.squareImg, classes.advider)}
                  style={{
                    backgroundImage:
                      'url(' + '/assets/images/adviser_kumagai.jpg' + ')',
                  }}
                />
                <p className={classes.playerName}>熊谷 紗希</p>
              </div>
              <div className={classes.playerInfo}>
                <p
                  className={classNames(classes.squareImg, classes.advider)}
                  style={{
                    backgroundImage:
                      'url(' + '/assets/images/adviser_kinga.jpg' + ')',
                  }}
                />
                <p className={classes.playerName}>近賀 ゆかり</p>
              </div>
              <div className={classes.playerInfo}>
                <p
                  className={classNames(classes.squareImg, classes.advider)}
                  style={{
                    backgroundImage:
                      'url(' + '/assets/images/adviser_otaki.jpg' + ')',
                  }}
                />
                <p className={classes.playerName}>大滝 麻未</p>
              </div>
              <div className={classes.playerInfo}>
                <p
                  className={classNames(classes.squareImg, classes.advider)}
                  style={{
                    backgroundImage:
                      'url(' + '/assets/images/adviser_4.webp' + ')',
                  }}
                />
                <p className={classes.playerName}>櫻本 尚子</p>
              </div>
            </div>
          </div>
          <div id="assistant-service-supporters" className="pb40">
            <p className={classes.largeSubTitle}>
              弁護士と相談の専門家も、みんなをサポートしてくれます！
            </p>
            {svgBackgruond}
            <div>
              <div className={classes.section}>【弁護士】</div>
              <div className={classes.flexBoxAround}>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>［関谷法律事務所］</span>
                    <br />
                    関谷綾子弁護士
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>
                      ［弁護士法人みやこ法律事務所］
                    </span>
                    <br />
                    小林聖子弁護士
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
              </div>
            </div>
            <div className="pt60">
              <div className={classes.section}>【相談の専門家】</div>
              <div className={classes.flexBoxAround}>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>
                      ［認定NPO法人3keys］
                    </span>
                    <br />
                    代表理事 森山誉恵
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
                <div
                  className={classNames(
                    classes.flexBoxAround2,
                    classes.width100
                  )}
                >
                  <p
                    className={classNames(classes.playerInfo, classes.support)}
                  >
                    <span className={classes.company}>
                      ［ストップイットジャパン株式会社］
                    </span>
                    <br />
                    CEO 谷山大三郎
                  </p>
                  {
                    //<p
                    //  className={classes.roundImg}
                    //  style={{ backgroundImage: "url(" + noImage + ")" }}
                    ///>
                  }
                </div>
              </div>
              {
                //<div className={classNames(classes.section, "mt60")}>
                //    【相談の専門家】
                //</div>
                //<div className={classes.flexBoxAround}>
                //  <p className={classNames(classes.playerInfo, classes.support)}>
                //      認定NPO法人3keys
                //    <br />
                //    代表理事 森山誉恵
                //  </p>
                //  <p className={classNames(classes.playerInfo, classes.support)}>
                //      ストップイットジャパン株式会社
                //    <br />
                //    CEO 谷山大三郎
                //  </p>
                //</div>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 40,
      fontFamily:
        '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    },
    componentWrapper: {
      margin: '0 auto',
      maxWidth: 1280,
      paddingTop: 0,
      paddingLeft: 40,
      paddingRight: 40,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    flexBoxBetweenRow: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
    },
    largeSubTitle: {
      width: '100%',
      fontSize: 28,
      fontWeight: 'bold',
      marginBottom: 14,
      paddingTop: 60,
      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
        paddingTop: 20,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 22,
      },
    },
    border: {
      size: 2,
      backgroundColor: 'white',
      height: 5,
    },
    hr: {
      height: 12,
      width: '100%',
      marginBottom: 20,
    },
    flexBoxCenter: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',
    },
    squareImg: {
      width: 210,
      height: 320,
      marginLeft: 5,
      marginRight: 5,
      marginBottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        width: 130,
        height: 200,
      },
      [theme.breakpoints.down('xs')]: {
        width: 60,
        height: 94,
      },
    },
    roundImg: {
      borderRadius: '50%',
      width: '200px',
      height: '200px',
      marginBottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        width: '130px',
        height: '130px',
        minWidth: '130px',
        minHeight: '130px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100px',
        height: '100px',
        minWidth: '100px',
        minHeight: '100px',
      },
    },
    playerInfo: {
      textAlign: 'center',
    },
    playerName: {
      fontSize: 20,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        marginTop: 15,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        marginTop: 7,
      },
    },
    flexBoxAround: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-around',
      alignContent: 'center',
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginTop: 5,
      },
    },
    flexBoxAround2: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'space-around',
      alignContent: 'center',
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
        marginTop: 8,
        alignContent: 'left',
        alignItems: 'center',
      },
    },
    width100: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    support: {
      margin: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 17,
        textAlign: 'left',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 17,
      },
    },
    smallCaution: {
      width: '100%',
      color: 'red',
      fontSize: 15,
      paddingLeft: 10,
    },
    section: {
      marginBottom: 10,
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
    },
    company: {
      fontWeight: 'bold',
    },
    advider: {
      transform: 'scale(0.9, 0.9)',
    },
  });

export default withStyles(styles)(AssistantServiceAdviser);
